<script>
  import { Command, CommandButton } from "svelte-command";
  export let serviceWorkerRegistration;
</script>

<tr>
  <td />
  <td>Scope</td>
  <td id="serviceWorkerScope">{serviceWorkerRegistration.scope}</td>
</tr>
<tr>
  <td />
  <td colspan="2">
    <CommandButton
      command={new Command(() => serviceWorkerRegistration.update(), {
        title: "Update",
        shortcuts: "Command+u"
      })}
    />
    <CommandButton
      command={new Command(() => serviceWorkerRegistration.unregister(), {
        title: "Unregister"
      })}
    />
  </td>
</tr>
