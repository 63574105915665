<script>
  export let name;
  export let version;
  export let description;
</script>

<tr>
  <td colspan="3">{name}</td>
</tr>
<tr>
  <td colspan="3">{description}</td>
</tr>
<tr>
  <td />
  <td>Version</td>
  <td>{version}</td>
</tr>
