<script>
  export let serviceWorker;
</script>

<tr>
  <td colspan="3">Service Worker</td>
</tr>
<tr>
  <td />
  <td>State</td>
  <td>{serviceWorker.state}</td>
</tr>
<tr>
  <td />
  <td>scriptURL</td>
  <td>{serviceWorker.scriptURL}</td>
</tr>
